import React, { useState } from 'react';
import emailjs from '@emailjs/browser';

function Contact() {

    //const form = useRef();
    const [inputs, setInputs] = useState({});
    const [mSent, setMSent] = useState(false);

    function setVisibilty(bool) {
        if(!bool) return "d-none"
        else return
    }

    function handleChange(event) {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
    }

    function sendEmail(e) {
        e.preventDefault();
        if(!inputs.c_name || !inputs.c_email || !inputs.c_message) return

        emailjs.send(process.env.REACT_APP_EJS_SERVICE, process.env.REACT_APP_EJS_TEMPLATE, inputs, process.env.REACT_APP_EJS_PUBLICKEY)
        .then((result) => {
            console.log("Message sent, why you looking at console anyway? You sus.");
            setMSent(true);
            setInputs({c_name : inputs.c_name.split(' ')[0]})
        }, (error) => {
            console.log(inputs);
            console.log(error.text);
        });
    };

    function ContactForm(props) {
        return (
            <div>
                <div className='h1 jb m-5'>Contact Me</div>
                <form onSubmit={sendEmail}>
                    <div className="container">
                        <div className={setVisibilty(!mSent) + ' row'}>
                            <div className="col-12">
                                <input
                                    className="c-inp p-2 px-3 m-4 jb fs-6"
                                    type="text" 
                                    name="c_name" 
                                    value={inputs.c_name || ""} 
                                    onChange={handleChange}
                                    placeholder="Name"
                                    autoComplete="off"
                                    required
                                />
                            </div>
                            <div className="col-12">
                                <input
                                    className="c-inp p-2 px-3 m-4 jb fs-6"
                                    type="email" 
                                    name="c_email" 
                                    value={inputs.c_email || ""} 
                                    onChange={handleChange}
                                    placeholder="Email"
                                    required
                                />
                            </div>
                            <div className="col-12">
                                <textarea
                                    className="c-inp p-2 px-3 m-4 jb fs-6"
                                    type="textarea" 
                                    rows={5}
                                    name="c_message"
                                    value={inputs.c_message || ""} 
                                    onChange={handleChange}
                                    placeholder="Message"
                                    autoComplete="off"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className='btn-hld'>
                        <input type="submit" className={setVisibilty(!mSent) + ' btn btn-rd p-2 px-3 m-4 jb'} value="Send"/>
                        <p className={setVisibilty(mSent) + ' p-2 m-4 jb fs-6 g81f'}>Message sent!</p>
                        <p className={setVisibilty(mSent) + ' p-2 m-4 jb fs-6'}> Thanks for writing to me, {inputs.c_name}. <br/>I'll get back to you as soon as I can.</p>
                    </div>
                </form>
            </div>
        )
    }

    return (
		<div>
            <ContactForm/>
        </div>
    )
}

export default Contact

// change from sendForm to send   https://www.emailjs.com/docs/sdk/send/
// restrict domain   https://dashboard.emailjs.com/admin/account/security