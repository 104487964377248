import './App.css';
import TheNav from './components/TheNav'
import TheFoot from './components/TheFoot'
import Projects from './components/Projects';
import Contact from './components/Contact';
import Skills from './components/Skills';
import Blog from './components/Blog';

function App() {
 
  return (
    <div className="App" >
      
      <div>
        <header id="about" className="App-header jb">
          <span className='text-left'>
            Hi, I'm Riju<br/>
            web developer / graphic designer<br/><br/>
            <span className='txt-s g81f'>
              <br/><br/>
              ❕ development build // scroll down
            </span>
          </span> 
          
        </header>
        <TheNav/>
        {/* <header id="skills" className="App-header"><Skills/></header> */}
        <header id="projects" className="App-header"><Projects/></header>
        <header id="blog" className="App-header"><Blog/></header>
        <header id="contact" className="App-header"><Contact/></header>
      </div>
       
      <TheFoot/>
    </div>
  );
}

export default App;
