import ScrollSpy from 'react-scrollspy';
import { useEffect } from 'react'
import rx_ico from '../resources/rx_sig_81ff00_256.png'

function TheNav () {

    useEffect(() => {

    });

    return(
        <nav id="theTopNav" className="navbar sticky-top navbar-expand-sm navbar-dark blaue">
                <div className="container-fluid">
                    <a className="navbar-brand jb" href="#about">
                        <img src={rx_ico} width="50" height="50" alt=""/>
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                        <ScrollSpy
                            className="navbar-nav ml-auto" 
                            items={['projects', 'contact']}
                            currentClassName='active'
                            componentTag={'div'}
                            offset={-300}>
                                {/* <a className="jb mx-3 nav-item nav-link active" href="#about">About</a> */}
                                {/* <a className="jb mx-3 nav-item nav-link" href="#skills">Skills</a> */}
                                <a className="jb mx-3 nav-item nav-link" href="#projects">Projects</a>
                                <a className="jb mx-3 nav-item nav-link" href="#contact">Contact</a>
                                <a className="jb mx-3 nav-item nav-link" href="https://blog.rixlayer.dev">Blog</a>
                        </ScrollSpy>
                    </div>
                </div>
            </nav>
    )
}

export default TheNav