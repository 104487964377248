import {useState, useEffect } from "react"

function Blog() {

    const [ articles, setArticles ] = useState([]);
    const [ aShowMore, setAShowMore ] = useState(false)

    const GQLQuery = `
        query GetUserArticles($page: Int!) {
            user(username: "rixlayer") {
                publication {
                    posts(page: $page) {
                        title
                        brief
                        dateAdded
                        coverImage
                        _id
                        slug
                    }
                }
            }
        }`

    useEffect(() => {
        async function gql(query, variables={}) {
            const data = await fetch('https://api.hashnode.com/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    query,
                    variables
                })
            });
        
            return data.json();
        }

        gql(GQLQuery, {page:0}).then(result => {
            const gqlarticles = result.data.user.publication.posts;
            setArticles(gqlarticles)
        })

    }, [GQLQuery])
    

    function ACard(props) {
        const url = `https://blog.rixlayer.dev/${props.a.slug}/`
        // const urldiv = <a className='d-flex' href={url} target='_blank' rel='noopener noreferrer'><img className='pbrd urlico align-self-center' src='https://i.imgur.com/lAM0HJQ.png' alt={url}/></a>
        let date = new Date(props.a.dateAdded)
        let aDate = `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()}`

        function articleBrief(brief, url) {
            return brief.substring(0, brief.substring(0, 100).lastIndexOf(" ")) + "..."
        }

        return(
            <div className='col-6 col-xl-4 blaue acard m-2' id={"acard" + props.a._id}>
                <div className='pbrd h-100 d-flex flex-column align-items-end'>
                    <img  className='pbrd acover w-100' src={props.a.coverImage} alt="hashnode_coverimage"></img>
                    <div className='pbrd ahov mb-3 w-100 h5 jb ptitle text-left'>{props.a.title}</div>
                    <div className='pbrd ahov mb-3 hov-desc txt-s text-left m2'>{articleBrief(props.a.brief, url)}</div>
                    <div className='pbrd ahov d-flex flex-row w-100 align-items-start'>
                        <div className='pbrd ahov p-1 text-left txt-s jb text-secondary w-100'>{aDate}</div>
                        <div className='pbrd ahov p-1 text-right txt-s jb text-secondary w-50'><a href={url}>view article</a></div>
                    </div>
                </div>
            </div>
        )
    }
        
    return(
        <div className="container-fluid py-5 my-5">
            <div className='h1 jb m-5'>Recent Blog Posts</div>
            <div className='d-flex flex-row flex-wrap p-2 justify-content-center' id='acardholder'>
                {articles.slice(0,(aShowMore)?6:3).map(a => <ACard key={a.id} a={a}/>)}
            </div>
            <button className='btn btn-rd p-2 px-3 m-4 jb' onClick={() => {setAShowMore(!aShowMore)}}>{(aShowMore)? "Show Less" : " Show More"}</button>
            <button className='btn btn-rd p-2 px-3 m-4 jb' onClick={() => window.location.href = 'https://blog.rixlayer.dev'}>Visit Blog</button>
        </div>
    )
}


export default Blog