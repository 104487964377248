import { Component } from "react";

class TheFoot extends Component {

    render() {
        // console messages
        let msg = "%cI see you like snooping around. That's pretty cool." +
        "\nI'm still working on this and would love to hear what you think about my page."
        
        console.log(msg, `color:#81ff00; font-size:24px;`)
        
        return(
            <div>
            </div>
        )
    }

}

export default TheFoot