import { useState } from 'react';
import p_data from '../data/projects.json'

function Projects() {

    const [ pShowMore, setPShowMore ] = useState(false);

    let plist = (pShowMore === false)
        ? p_data.slice(0,3)
        : p_data;

    function handleShowMore() {
        setPShowMore(!pShowMore);
        document.getElementById("pcard" + plist[2].id).scrollIntoView({behavior: "smooth", block: "center"});
    }

    function getTypeIcon(type) {
        let img = (type === 'webapp')
                ? <img className='pbrd typeico align-self-center' src='https://imgur.com/PAB48ii.png' alt={type}/>
                : (type === 'chatbot')
                ? <img className='pbrd typeico align-self-center' src='https://imgur.com/ELdRBV2.png' alt={type}/>
                : (type === 'software')
                ? <img className='pbrd typeico align-self-center' src='https://imgur.com/wgbH8MF.png' alt={type}/>
                : (type === 'app')
                ? <img className='pbrd typeico align-self-center' src='https://imgur.com/HSqAvrg.png' alt={type}/>
                : (type === 'game')
                ? <img className='pbrd typeico align-self-center' src='https://imgur.com/HFgfZKv.png' alt={type}/>
                : null
        return img
    }

    function PCard(props) {
        if(!props.p.id) return (null)

        let urldiv = (props.p.url !== "")
        ? <a className='d-flex' href={props.p.url} target='_blank' rel='noopener noreferrer'><img className='pbrd urlico align-self-center' src='https://i.imgur.com/lAM0HJQ.png' alt={props.p.url}/></a>
        : null

        let gitdiv = (props.p.git !== "")
        ? <a className='d-flex' href={props.p.git} target='_blank' rel='noopener noreferrer'><img className='pbrd urlico align-self-center' src='https://i.imgur.com/UWFLyeq.png' alt={props.p.git}/></a>
        : null

        let ytdiv = (props.p.yt !== "")
        ? <a className='d-flex' href={props.p.yt} target='_blank' rel='noopener noreferrer'><img className='pbrd urlico align-self-center' src='https://i.imgur.com/vTyiV2L.png' alt={props.p.yt}/></a>
        : null

        return(
            <div className='col-4 col-xl-3 blaue pcard m-2' id={"pcard" + props.p.id}>
                <div className='pbrd h-100 d-flex flex-column align-items-end'>
                    <div className='pbrd mb-3 d-flex flex-row w-100 align-items-start'>
                        {getTypeIcon(props.p.type)}
                        <div className='pbrd p-1 text-left txt-s jb text-secondary w-100'>{props.p.for}</div>
                        <div className='pbrd p-1 text-right txt-s jb text-secondary w-50'>{props.p.date}</div>
                        {ytdiv}{gitdiv}{urldiv}
                    </div>
                    <div className='pbrd mb-3 w-100 h5 jb ptitle text-left'>{props.p.title}</div>
                    <div className='pbrd mb-3 hov-desc txt-m text-left m2'>{props.p.desc}</div>
                    <div className='pbrd mt-auto d-flex flex-wrap justify-content-end'>
                        {props.p.t.main.map(t => <p className='pbrd txt-s mx-2 mb-0 jb text-secondary'>{t}</p>)}
                    </div>
                </div>
            </div>
        )
    }


    return(
        <div className="container-fluid py-5 my-5">
            <div className='h1 jb m-5'>Projects</div>
            <div className='d-flex flex-row flex-wrap p-2 justify-content-center' id='pcardholder'>
                {plist.map(p => <PCard key={p.id} p={p}/>)}
            </div>
            <button className='btn btn-rd p-2 px-3 m-4 jb' onClick={() => {handleShowMore()}}>{(pShowMore)? "Show Less" : " Show More"}</button>          
        </div>
    )
}

export default Projects


/*
Xtrain LB
Xtrain DT4S

Discord JS
- Malakh
- Crewmate

Unity 3D
- Reforger
- Radkrieg

SDN Link Migration
Rix Audio
666 Stock
666 Stock Native


-----



game
chatbot
webapp
mobileapp
software

*/

